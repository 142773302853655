import React, { useEffect, useState } from 'react'
import WalletCard from '../../../Components/User/Wallet/WalletCard'
import UserNavbar from '../../../Components/Navbar/UserNavbar'
import WalletHistory from '../../../Components/User/Wallet/WalletHistory'
import { ShimmerWalletHistory } from '../../../Components/Shimmer/User/ShimmerWalletHistory'
import { ShimmerWalletBalance } from '../../../Components/Shimmer/User/ShimmerWalletBalance'





function WalletPage() {
const [walletHistory,setWalletHistory] = useState([])
// const [isLoading,setLoading] = useState(true)
// useEffect(()=>{
//   console.log('wallete histr',walletHistory);
  
//   if(walletHistory.length > 0){
//     setLoading(false)
//   }
// },[walletHistory])
  return (
    <>
   
    <UserNavbar/>
    <div className=' w-[30%] h-screen flex flex-col justify-center items-center gap-8 py-[7rem]'>
    <WalletCard component={'userBalance'}/>
    <WalletCard component={'addMoney'} setWalletHistory={setWalletHistory}/>
    </div>
     <WalletHistory userType={'user'} walletHistory={walletHistory} setWalletHistory={setWalletHistory} />
    </>
  )
}

export default WalletPage
