export const ShimmerWalletHistory = () => {
    return (
      <div className="fixed top-[8rem] right-12 w-[60rem] h-[75dvh] bg-white p-6 shadow-xl rounded-lg overflow-auto border border-gray-200">
        
        <div className="w-1/3 h-8 bg-gray-200 rounded-md shimmer mb-4"></div>

        <div className="grid grid-cols-4 gap-4 bg-gray-100 py-3 px-6 rounded-md">
          <div className="w-1/2 h-6 bg-gray-300 rounded-md shimmer"></div>
          <div className="w-1/2 h-6 bg-gray-300 rounded-md shimmer"></div>
          <div className="w-1/4 h-6 bg-gray-300 rounded-md shimmer"></div>
          <div className="w-1/4 h-6 bg-gray-300 rounded-md shimmer"></div>
        </div>
  
        <div className="divide-y divide-gray-200">
          {Array.from({ length: 6 }).map((_, index) => (
            <div
              key={index}
              className="grid grid-cols-4 gap-4 py-4 px-6 hover:bg-gray-50 transition-colors duration-200"
            >
              <div className="w-1/2 h-6 bg-gray-200 rounded-md shimmer"></div>
              <div className="w-full h-6 bg-gray-200 rounded-md shimmer"></div>
              <div className="w-1/4 h-6 bg-gray-200 rounded-md shimmer"></div>
              <div className="w-1/4 h-6 bg-gray-200 rounded-md shimmer"></div>
            </div>
          ))}
        </div>
  
        <div className="flex justify-center mt-4">
          {Array.from({ length: 5 }).map((_, index) => (
            <div
              key={index}
              className="w-8 h-8 bg-gray-200 rounded-full shimmer mx-2"
            ></div>
          ))}
        </div>
      </div>
    );
  };
  
