import React from 'react'
import PaymentSucessFullPage from '../../../Components/PaymentSuccess/PaymentSucessFullPage'

function PaymentSucess() {
  return (
    <>
    <PaymentSucessFullPage/>
    </>
  )
}

export default PaymentSucess
