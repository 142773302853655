import React from "react";
import { IoIosNotifications } from "react-icons/io";
import { FaRegUser } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { adminLogout } from "../../Features/Admin/adminActions";
import { resetAdminState } from "../../Features/Admin/adminSlice";

function AdminNavbar() {
  const dispatch = useDispatch()
  const handleLogout =()=>{
    dispatch(adminLogout())
    dispatch(resetAdminState())
  }
  return (

    <nav className='fixed top-0 left-[15rem] flex flex-row justify-around items-center  h-[5rem] drop-shadow-lg w-[97dvw] 9 bg-white z-50 border'>
      <div className="w-1/3 h-10 flex">
    </div >
    <div className="flex gap-[3rem]">
      <button onClick={handleLogout}>Logout</button>
      </div> 
    </nav>

  );
}

export default AdminNavbar;
