import React, { useContext } from "react";
import { createPortal } from "react-dom";
import { useNavigate } from "react-router-dom";
import { driverLiveLocation } from "../../../Context/DriverLocation";

function NoDriverAvailable() {
const {setRideAvailable}  =    useContext(driverLiveLocation)
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/");
    setRideAvailable(true)
  };
  return createPortal(
    <>
      <div className="fixed inset-0 flex justify-center items-center bg-slate-900 bg-opacity-75 z-40 transition-opacity duration-300 ease-in-out">
        <div className="flex flex-col w-[90%] max-w-md h-auto rounded-lg bg-white shadow-lg p-8 items-center gap-6 relative transition-transform transform scale-100 ease-out duration-300 border-2 border-red-500">
            <div className="flex justify-center">
            <img src="/assets/driver.webp" alt="" className="w-1/2" />
            </div>
            <div className="flex flex-col gap-3">
                <h1 className="font-semibold text-lg text-center">Sorry, No Drivers Available</h1>
                <p className="break-words">We couldn't find a driver nearby to accept your ride request at the moment. Please try again in a few minutes, or adjust your pickup location to improve your chances of finding a driver.</p>
            </div>
            <button className="bg-black text-white p-2 rounded-md" onClick={handleNavigate}>Go Home</button>
        </div>
      </div>
    </>,
    document.getElementById("cancel-modal")
  );
}

export default NoDriverAvailable;
